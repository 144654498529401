import React, { useState, useEffect } from "react"
import Layout from '../containers/layout'
import FlyingStar from '../containers/FlyingStar'
import styled from 'styled-components'
import PeriodPicker from '../components/Picker/Period'
import DirectionPicker from '../components/Picker/Direction'
import { createFlyingstarChart, getAnnualStar, getMonthlyStar, createBasePlate } from '../cm-lib/XuanKongFlyingStar'
import { Box, Button, DateInput, Layer, TextArea, TextInput, List } from 'grommet'
import { TWENTY_FOUR_MOUNTAIN } from '../cm-lib/constants'
import { create } from "react-test-renderer"
import { setStorage, getStorage } from '../helpers/index'
import { CHARTLIST_KEY } from '../constants' 

const Home = () => {

  const [caselist, setCaseList] = useState([])

  useEffect(() => {
    const item = getStorage(CHARTLIST_KEY, []);
    setCaseList(item)

  }, [])

  return <Layout>
    <List
    primaryKey="name"
    secondaryKey="percent"
    data={caselist}
    >
        {
            datum => <Box direction="row" gap="medium">
                <Box>
                { datum.name }
                </Box>
                <Box>
                { datum.description }
                </Box>
            </Box>
        }

    </List>
  </Layout>
}

export default Home
